import React, { Component } from 'react'
import Slider from '../shared/Slider1'

export default class Downloads extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            sources: [],
            childrenWidth: 0,
            childrenHeight: 0,
            margin: 0,
            currentShownItem: 1
        }
    }

    componentDidMount() {
        this.setValues(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.content !== nextProps.content) {
            this.setValues(nextProps);
        }
    }

    setValues(props) {
        let sources = [];
        props.content.map(source => {
            let lines = source.name.split('<br/>');
            sources.push({
                ...source,
                name:<text x="0" y="0.8" textAnchor="left" fontWeight="500"
                    fontSize="0.8" fill="none" strokeWidth=".015" stroke="#fff" 
                    fontFamily="sans-serif">
                        {
                            lines.map((line,i) => <tspan key={i} x="0" dy={i+''}>{line}</tspan>)
                        }
                    </text>
            })
        });
        this.setState({sources},() => {
            this.checkScreenSize();
        });
    }

    checkScreenSize = () => {
        if(window.innerWidth > 700) {
            this.setState({childrenWidth: 350, childrenHeight: 200, margin: 120});
        } else {
            this.setState({childrenWidth: window.innerWidth/100*90, childrenHeight: window.innerHeight/100*35, margin: window.innerWidth/100*20});
        }
    }

    updateTitlesClass = (index) => {  
        index = !index ? 0 : index;
        this.setState({currentShownItem: (index + 1) % this.state.sources.length === 0 ? this.state.sources.length : (index + 1) % this.state.sources.length});
    }

    render() {

        const renderSlider = () => {
            return (
                <Slider order={window.innerWidth > 700 ? ['reverse','normal']: ['normal']} style={{
                    width: '100%',
                    height: '100vh',
                    justifyContent: 'center'
                }}
                axis='x'
                ajust={true}
                childrenClass='download'
                childrenWidth={this.state.childrenWidth} 
                childrenHeight={this.state.childrenHeight} 
                childrenMargin={this.state.margin}
                onMainItemChange={this.updateTitlesClass}>
                    {this.state.sources.map((source, i) => {
                        return (
                            <div key={i} className='download'>                                 
                                <div className='download-box'>
                                    <svg viewBox="0 0 10 3" className='title'>
                                        {source.name}
                                    </svg>
                                    <a href={process.env.PUBLIC_URL + '/files/' + source.see} target={'_blank'}><p className='link open-link'>VER</p></a>
                                    <a href={process.env.PUBLIC_URL + '/files/download/' + source.download} target={'_blank'}><p className='link download-link'>download</p></a>
                                    <div className='img-container'>
                                        <img src={process.env.PUBLIC_URL + '/images/sources/'+ source.img}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            )
        }

        const counter = () => {
            if(window.innerWidth <= 700) {
                return (
                    <div className='counter'>
                        <div className='progress-bar'>
                            <div className='bar' style={{
                                width: this.state.currentShownItem*100/this.state.sources.length + '%'
                            }}></div>
                        </div>
                        <p>{this.state.currentShownItem + '/' + this.state.sources.length}</p>
                    </div>
                )
            }
        }

        return (
            <div className='session downloads'>
                {
                    this.state.sources.length > 0 ?
                    renderSlider() :
                    <p></p>
                }
                {counter()}
            </div>
        )
    }
}