import React, { Component } from 'react';
import OpenedProjectType1 from './OpenedProjectType1';
import OpenedProjectType2 from './OpenedProjectType2';
import { gsap } from 'gsap';
import OpenedProjectType3 from './OpenedProjectType3';

export default class ProjectsOpened extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imagesLoaded: false,
            scroll: 0,
            class: 'project-details session',
            canScroll: true,
            canJumpCount: 0,
            barWidth: 0,
            timeout: null,
            project: {
                name: '',
                main_img: '',
                first_img: '',
                first_text: '',
                second_img: '',
                thrid_img: ''
            },
            projectsPositions: [],
            projectsWidths: [],
            pressed: false,
            language: '',
            animation: gsap.timeline()
        }
    }

    // componentDidUpdate(newState, newProps) {
    //     console.log(newState, newProps)
    //     if(!this.state.hasProjectsPositions && newProps.projectsPositions && newProps.projectsPositions.length > 0) {
    //         // this.setState({hasProjectsPositions: true}, () => this.setValues(this.props));
    //     };
    // }

    componentDidMount() {
        this.setState({language: localStorage.getItem('language')});
        this.projects.style.transition = 'inherit';
        this.setState({class: 'project-details session animate'});
        const imagesLoaded = Array.from(document.querySelectorAll("img"));
        let i = 0;
        imagesLoaded.forEach((image) => {
          image.addEventListener("load", () => {
                i+=1;
                if(i === imagesLoaded.length) {
                    setTimeout(() => {
                        this.setValues(this.props);
                        this.setState({imagesLoaded: true});
                    }, 100);
                };
          });
        //   image.addEventListener("error", () => updateStatus(imagesLoaded), {
        //     once: true
        //   });
        });
        // setTimeout(() => {this.projects.style.transition = 'all 1.5s cubic-bezier(0, 0.98, 0.33, 0.93) 0s';}, 700);
        window.addEventListener('resize', this.onResize);
    }

    onResize = () => {
       this.setState({
            scroll: 0,
            class: 'project-details session',
            canScroll: true,
            canJumpCount: 0,
            barWidth: 0,
            projectsPositions: [],
            projectsWidths: [],
       }, () => {
        setTimeout(() => {
            this.setState({class: 'project-details session animate'});
            this.setValues(this.props);
        }, 100);
       });
    }
    
    setValues = (props, reload) => {
        let initialPositions = [];
        let projectsPositions = [];
        let projectsWidths = [];
        let project = props.projects.filter(x => x.id === props.selectedProject)[0];
        this.setState({project});
        // this.setState({class: reload ? 'project-details session animateReload' : 'project-details session animate'});
        props.projects.map((project, i) => {
            let position = i === 0 ? -0 : -document.getElementById(this.props.projects[i-1].id).clientWidth
            initialPositions.push(position);
            projectsPositions.push(i === 0 ? -0 : initialPositions.reduce((x, y) => x + y))
            projectsWidths.push(parseInt(document.getElementById(project.id).clientWidth));
        });
        this.setState({projectsPositions, projectsWidths}, () => 
        this.jumpToProject(props.selectedProject));
        props.toggleProject(props.projects[props.selectedProject-1]);
    }

    jumpToProject(selectedProject, notInital) {
        this.props.projects.map((project, index) => {
            if(project.id === +selectedProject) {
                if(window.innerWidth <= 700) {
                    // this.projects.style.transition = 'all 0.7s cubic-bezier(0, 0.98, 0.33, 0.93) 0s';
                }
                this.setState({canScroll: false});
                this.setState({scroll: this.state.projectsPositions[project.id-1]}, () => {
                    this.state.animation.clear();
                    this.state.animation.to('.opened-projects-content', {duration: notInital ? 0.7 : 0, x: this.state.scroll, ease: "power3"});
                });
                this.timeout = setTimeout(() => {
                    // this.projects.style.transition = 'all 1.5s cubic-bezier(0, 0.98, 0.33, 0.93) 0s';
                    this.setState({canScroll: true}); this.setState({barWidth: 0});}, 1000);
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        window.removeEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.language && localStorage.getItem('language') !== this.state.language) {
            this.setState({language: localStorage.getItem('language')}, () => this.onResize());
        }
        if(nextProps.closing) {
            this.setState({class: 'project-details session reverse'});
        } else if(this.props.selectedProject !== nextProps.selectedProject && localStorage.getItem('language') === this.state.language) {
            this.jumpToProject(nextProps.selectedProject, true);
        }
    }

    changeBarWidth = () => {
        let project = this.props.projects.filter(x => x.id === this.props.selectedProject)[0];
        this.setState({barWidth: (((this.state.scroll - this.state.projectsPositions[project.id-1])*-1) / (this.state.projectsWidths[project.id-1] - window.innerWidth - 350)  ) * 100 });
    }

    onScroll = (e) => {
        if(this.state.canScroll) {
            if (e.deltaY > 0) {
                if(this.state.scroll >= window.innerWidth+(this.state.projectsPositions[this.state.projectsPositions.length -1]-this.state.projectsWidths[this.state.projectsWidths.length -1])) {
                    this.setState({scroll: this.state.scroll-=100}, () => {
                        this.changeBarWidth();
                        this.state.animation.clear();
                        this.state.animation.to('.opened-projects-content', {duration: 2, x: this.state.scroll, ease: "power3"});
                    });
                    this.props.projects.map((project, index) => {
                        if (this.state.scroll <= this.state.projectsPositions[index]+window.innerWidth+350
                        && project.id > this.props.selectedProject) {
                            if(this.state.canJumpCount > 10) {
                                this.props.toggleProject(project);
                                this.setState({canScroll: false});
                                this.setState({canJumpCount: 0});
                            } else {
                                this.setState({scroll: this.state.projectsPositions[index]+window.innerWidth+350}, () =>
                                this.changeBarWidth());
                                this.setState({canJumpCount: this.state.canJumpCount+=1});
                            }
                        }
                    });
                }
            } else {
                if(this.state.scroll < 0) {
                    this.setState({scroll: this.state.scroll+=100}, () => {   
                        this.changeBarWidth();
                        this.state.animation.clear();
                        this.state.animation.to('.opened-projects-content', {duration: 2, x: this.state.scroll, ease: "power3"});});
                    this.props.projects.map((project, index) => {
                        if (this.state.scroll >= this.state.projectsPositions[index]
                        && project.id === this.props.selectedProject) {
                            if(this.state.canJumpCount > 10) {
                                this.props.toggleProject(this.props.projects[index-1]);
                                this.setState({canScroll: false});
                                this.setState({canJumpCount: 0});
                            } else {
                                this.setState({scroll: this.state.projectsPositions[index]}, () =>
                                this.changeBarWidth());
                                this.setState({canJumpCount: this.state.canJumpCount+=1});
                            }
                        }
                    });
                }
            }
        }
    }

    onMouseDown = (e) => {
        let event = e.touches ? e.touches[0] : e;
        this.setState({currentPosition: event.clientX});
        this.setState({pressed: true});
    }

    onMouseMove = e => {
        if(this.state.pressed && this.state.canScroll) {
            let event = e.touches ? e.touches[0] : e;
            let val = window.innerWidth > 700 ? this.state.currentPosition - event.clientX : (this.state.currentPosition - event.clientX)*3;
            if(this.state.scroll <= 0 && this.state.scroll >= window.innerWidth-this.projects.clientWidth) {
                this.setState({currentPosition: event.clientX});
                this.changeBarWidth();
                this.setState({scroll: this.state.scroll - val}, () => {
                    this.state.animation.clear();
                    this.state.animation.to('.opened-projects-content', {duration: 2, x: this.state.scroll, ease: "power3"});
                    if(this.state.scroll <= 0 && this.state.scroll >= window.innerWidth-this.projects.clientWidth) {
                        if (val > 0) {
                            this.props.projects.map((project, index) => {
                                if (this.state.scroll <= this.state.projectsPositions[index]+window.innerWidth+350
                                && project.id > this.props.selectedProject) {
                                    if(this.state.canJumpCount > 10) {
                                        this.props.toggleProject(project);
                                        this.setState({canScroll: false});
                                        this.setState({canJumpCount: 0});
                                    } else {
                                        this.setState({scroll: this.state.projectsPositions[index]+window.innerWidth+350}, () =>
                                        this.changeBarWidth());
                                        this.setState({canJumpCount: this.state.canJumpCount+=1});
                                    }
                                }
                            });
                        } else {
                            this.props.projects.map((project, index) => {
                                if (this.state.scroll >= this.state.projectsPositions[index]
                                && project.id === this.props.selectedProject) {
                                    if(this.state.canJumpCount > 10) {
                                        this.props.toggleProject(this.props.projects[index-1]);
                                        this.setState({canScroll: false});
                                        this.setState({canJumpCount: 0});
                                    } else {
                                        this.setState({scroll: this.state.projectsPositions[index]}, () =>
                                        this.changeBarWidth());
                                        this.setState({canJumpCount: this.state.canJumpCount+=1});
                                    }
                                }
                            });
                        }
                    }
                });
            } else {
                let scroll = this.state.scroll >= 0 ? -1 : window.innerWidth-this.projects.clientWidth+1
                this.setState({scroll});
            }
        }
    }

    onMouseEnter = () => {
        this.setState({pressed: false});
    }

     onClick = () => {
        this.setState({currentPosition: 0});
        this.setState({pressed: false});
    }

    onClose = () => {
        this.props.history.push('/projects');
    }

    render() {
        return (
            <div 
            className={this.state.class} 
            onWheel={this.onScroll}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onTouchStart={this.onMouseDown}
            onTouchMove={this.onMouseMove}
            onMouseEnter={this.onMouseEnter}
            onClick={this.onClick}>
                <div className='close-icon' onClick={this.onClose}>
                    <span></span>
                    <span></span>
                </div>
                {
                    !this.state.imagesLoaded &&
                    <div className='projects-loader'>
                        <div className='line'>
                            <div className='line-content'></div>
                        </div>
                    </div>
                }
                <div ref={ref => this.projects = ref} className='text-selection-diabled pointer-none opened-projects-content'>
                    {/* <div style={{position: 'fixed', top: 20, right: 20, color: 'black', fontSize: 20, cursor: 'pointer'}}
                    onClick={this.onClose}>X</div> */}
                    {
                        this.props.projects.map((project, index) => {
                            if (project.style === 1) {
                                return <OpenedProjectType1 key={project.id} project={project} next={this.props.next} isTheLast={index === this.props.projects.length - 1}
                                className={project.id === this.props.selectedProject ? 
                                'opened-project selected' : 'opened-project non-selected'}/> 
                            } else if (project.style === 2) {
                                return <OpenedProjectType2 key={project.id} project={project} next={this.props.next} isTheLast={index === this.props.projects.length - 1}
                                className={project.id === this.props.selectedProject ? 
                                'opened-project selected' : 'opened-project non-selected'}/> 
                            } else if (project.style === 3) {
                                return <OpenedProjectType3 key={project.id} project={project} next={this.props.next} isTheLast={index === this.props.projects.length - 1}
                                className={project.id === this.props.selectedProject ? 
                                'opened-project selected' : 'opened-project non-selected'}/> }             
                        })
                    }
                </div>
                <div className={this.state.canScroll ? "project-bar shown" : "project-bar hidden"}>
                    <div className='bar' style={{width: this.state.barWidth + '%'}}></div>
                </div>
            </div>
        )
    }
}