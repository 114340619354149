import React, { Component } from 'react'

export default class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            percentage: 0,
            text: <h1 className='loader-text'>knowledge</h1>,
            texts: [
                <h1 className='loader-text'>knowledge</h1>,
                <h1 className='loader-text'>flexibility</h1>,
                <h1 className='loader-text'>self motivation</h1>,
                <h1 className='loader-text'>fearless</h1>,
                <h1 className='loader-text'>cooperation</h1>,
                <h1 className='loader-text'>watcher</h1>,
                <h1 className='loader-text'>resilience</h1>,
                <h1 className='loader-text'>good vibes</h1>
            ]
        }
    }

    barInterval;
    textInterval;

    componentDidMount() {
        let num = 0;
        this.textInterval = setInterval(() => {
            num+=1;
            this.setState({text: this.state.texts[num]});
        }, 300);
        this.barInterval = setInterval(() => {
            this.setState({percentage: this.state.percentage+= ((100 / 2400) * 100)  });
        }, 100);
        setTimeout(() => {
            clearInterval(this.textInterval);
        }, 2000);
        setTimeout(() => {
            this.setState({percentage: 100 });
            clearInterval(this.barInterval);
        }, 2000);
    }

    render() {
        return (
            <div className='loader'>
                {this.state.text}
                <div className='loader-bar'>
                    <span style={{width: this.state.percentage + 'px'}}></span>
                </div>
            </div>
        )
    }
}
