import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Content } from '../images/404.svg';
import { ReactComponent as Arrow } from '../images/ArrowRight.svg';

export default class NotFound extends Component {
    render() {
        return (
            <div className='session not-found'>
                <div>
                    <Content className='content'/>
                    <Link to='/'><Arrow className='arrow'/></Link>
                </div>
            </div>
        )
    }
}
