import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import MenuList from './MenuList';
import Curriculum from '../assets/curriculo.pdf';

class SideMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            menuClassName: 'side-menu',
            canToggle: true
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.menuClassName !== 'side-menu') {
            this.toggleMenu();
        }
    }

    toggleMenu = () => {
        if(this.state.canToggle) {
            this.setState({canToggle: false});
            this.setState({showMenu: true});
            this.props.renderStatus(true);
            this.setState({ menuClassName:  this.state.menuClassName.includes('opened') ? 'side-menu closed' : 'side-menu'}, () => {
                if(this.state.menuClassName === 'side-menu') {
                    setTimeout(() => {
                        this.setState({menuClassName: 'side-menu opened'});
                    });
                }
            });
            setTimeout(() => {
                if(this.state.menuClassName == 'side-menu closed') {
                    this.setState({menuClassName: 'side-menu'});
                    this.setState({showMenu: false});
                    this.props.renderStatus(false);
                }
                this.setState({canToggle: true});
            }, 400);
        }
    }
    
    render() {

        const contactInfo = () => {
            if(window.innerWidth >= 700) {
                return (
                    <div className='contact-info'>
                        <b className='phone'><p>+55 11 98920-9049</p></b>
                        <b className='email'><p>contact@brunocesar.digital</p></b>
                        <Link target='_blank' to={process.env.PUBLIC_URL + '/files/curriculo.pdf'}><p className='curriculum'>{this.props.content.menu.see_curriculum}</p></Link>
                    </div>
                )
            } else {
                return (<Link to='/contact'><b className='contact-info-link'>{this.props.content.menu.contact}</b></Link>)
            }
        }

        const renderMenu = () => {
            if(this.state.showMenu) {
                return (
                    <div className={this.state.menuClassName}>
                        {contactInfo()}
                        <MenuList items={this.props.content.menu.menu_items} isOpend={this.state.menuClassName.includes('opened') ? true : false} />
                    </div>
                )
            }
        }

        return (
            <div>
                <div ref={this.props.setRef} className={this.state.menuClassName.includes('opened') ? 
                `menu-icon ${this.props.menuColor} opened` : `menu-icon ${this.props.menuColor} closed`} 
                style={this.props.style}
                onClick={this.toggleMenu}>
                    <span></span>
                    <span></span>
                </div>
                {renderMenu()}
            </div>
        )
    }
}

export default withRouter(SideMenu)