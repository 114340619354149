import React, { Component } from 'react';
import { gsap } from 'gsap';

export default class Galery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            galery: [],
            scroll: 55,
            scrolling: false,
            animation: gsap.timeline(),
            currentShownItem: 1,
            positions: []
        }
    }

    componentDidMount() {
        this.setState({galery: this.props.content.images},() => {
            let positions = [];
            for(let i = 0; i < this.state.galery.length; i++) {
                positions.push(this.galery.childNodes[i].getBoundingClientRect().left);
            }
            this.setState({positions});
            this.state.animation.clear();
            this.state.animation.to('.image', {duration: 0, x: this.state.scroll, ease: "power3"});
        });
    }

    onScroll = (e) => {
        if(!this.state.scrolling) {
            setTimeout(() => {this.setState({scrolling: true});}, 200);
            setTimeout(() => {this.setState({scrolling: false});}, 2500);
        };
        if (e.deltaY > 0) {
            if(this.state.scroll >= window.innerWidth-parseFloat(this.galery.clientWidth)) {
                this.setState({scroll: this.state.scroll-=100}, () => {
                    this.setCurrentItem();
                    this.state.animation.clear();
                    this.state.animation.to('.image', {duration: 2, x: this.state.scroll, ease: "power3"});
                });
            }
        } else {
            if(this.state.scroll <= 0) {
                this.setState({scroll: this.state.scroll+=100}, () => {
                    this.setCurrentItem();
                    this.state.animation.clear();
                    this.state.animation.to('.image', {duration: 2, x: this.state.scroll, ease: "power3"});
                });
            }
        }
    }

    onMouseDown = (e) => {
        let event = e.touches ? e.touches[0] : e;
        this.setState({currentPosition: event.clientX});
        this.setState({pressed: true});
    }

    onMouseMove = e => {
        if(this.state.pressed) {
            if(!this.state.scrolling) {
                setTimeout(() => {this.setState({scrolling: true});}, 200);
                setTimeout(() => {this.setState({scrolling: false});}, 2500);
            };
            
            let event = e.touches ? e.touches[0] : e;
            let val = window.innerWidth > 700 ? this.state.currentPosition - event.clientX : (this.state.currentPosition - event.clientX)*3;
            if(this.state.scroll <= 55 && this.state.scroll >= window.innerWidth-parseFloat(this.galery.clientWidth)) {
                this.setState({currentPosition: event.clientX});
                this.setState({scroll: this.state.scroll - val}, () => {
                    this.setCurrentItem();
                    this.state.animation.clear();
                    this.state.animation.to('.image', {duration: 2, x: this.state.scroll, ease: "power3"});
                });
            } else {
                let scroll = this.state.scroll >= 55 ? 54 : window.innerWidth-parseFloat(this.galery.clientWidth)+1
                this.setState({scroll}, () => {
                    this.setCurrentItem();
                    this.state.animation.clear();
                    this.state.animation.to('.image', {duration: 2, x: this.state.scroll, ease: "power3"});
                });
            }
        }
    }

    onMouseEnter = () => {
        this.setState({pressed: false});
    }

     onClick = () => {
        this.setState({currentPosition: 0});
        this.setState({pressed: false});
    }

    setCurrentItem = () => {
        for(let i = 0; i < this.state.positions.length; i++) {
            if(window.innerWidth < 700) {
                if(this.state.scroll * -1 > (this.state.positions[i]/100)*80 && i+1 > this.state.currentShownItem) {
                    this.setState({currentShownItem: i+1});
                    break;
                } else {
                    if(this.state.scroll * -1 < (this.state.positions[i]/100)*80 && i < this.state.currentShownItem && i !== 0) {
                        this.setState({currentShownItem: i});
                        break;
                    }
                }
            } else if (window.innerWidth >= 700 && window.innerWidth < 1100) {
                if(this.state.scroll * -1 > (this.state.positions[i]/100)*62 && i+1 > this.state.currentShownItem) {
                    this.setState({currentShownItem: i+1});
                    break;
                } else {
                    if(this.state.scroll * -1 < (this.state.positions[i]/100)*62 && i < this.state.currentShownItem && i !== 0) {
                        this.setState({currentShownItem: i});
                        break;
                    }
                }
            } else if (window.innerWidth >= 1100) {
                if(this.state.scroll * -1 > (this.state.positions[i]/100)*1 && i+1 > this.state.currentShownItem) {
                    this.setState({currentShownItem: i+1});
                    break;
                } else {
                    if(this.state.scroll * -1 < (this.state.positions[i]/100)*80 && i < this.state.currentShownItem && i !== 0) {
                        this.setState({currentShownItem: i});
                        break;
                    }
                }
            }
        }
    }
    
    render() {
        return (
            <div style={{
                height: '100%'
            }}>
                <div 
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onTouchStart={this.onMouseDown}
                onTouchMove={this.onMouseMove}
                onMouseEnter={this.onMouseEnter}
                onClick={this.onClick}
                style={{
                    transition: '1s',
                    transform: this.state.scrolling ? 'scale(0.8)' : 'scale(1)',
                    height: '100%'
                }}>
                    <div className='session text-selection-diabled' onWheel={this.onScroll}>
                        <div ref={ref => this.galery = ref} className='gallery'
                        style={{position: 'fixed'}}>
                            {this.state.galery.map((image, index) => {
                                return(
                                    <div className={index % 2 === 0 ? 'image ver1' : 'image ver2'} 
                                    key={index} style={{
                                        // transform: this.state.scrolling ? 'scale(0.8)' : 'scale(1)',
                                        zIndex: index % 2 === 0 ? 1 : 2
                                    }}>
                                        <img className='text-selection-diabled' onDragStart={e => console.log(e)} src={process.env.PUBLIC_URL + '/images/gallery/' + image} />
                                    </div>
                                )
                            })}
                            <h1 className='title text-selection-diabled'>{this.props.content.gallery}</h1>
                        </div>
                    </div>
                </div>
                <div className='counter'>
                    <div className='progress-bar'>
                        <div className='bar' style={{
                            width: this.state.currentShownItem*100/this.state.galery.length + '%'
                        }}></div>
                    </div>
                    <p>{this.state.currentShownItem + '/' + this.state.galery.length}</p>
                </div>
            </div>
        )
    }
}
