import React, { Component } from 'react';
import { gsap } from "gsap";

 class Slider extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      scroll: 0,
      scrollReverse: 0,
      canScroll: true,
      children: [],
      animations_normal: [],
      animations_reverse: [],
      multipliers_normal: [],
      multipliers_reverse: [],
      childrenWidth: 0,
      childrenMargin: 0,
      totalSize: 0,
      teste: false,
      pressed: false,
      positions: [],
      classes: [],
      currentMainItem: 0,
      childrenSize: 0,
      closed: false
    }
  }
  

  componentDidMount() {
    // console.log(this.props);
    // this.setChildren(this.props);
    // setTimeout(() => {
    //   // console.log(this.props);
    //   this.setChildren(this.props);
    // }, 700);
    window.addEventListener('resize', this.onResize);
  }

  componentWillReceiveProps(nextProps) {
    // if((this.props.axis, this.props.children, this.props.childrenHeight, this.props.childrenWidth, this.props.childrenMargin) 
    // !== (nextProps.axis, nextProps.children, nextProps.childrenHeight, nextProps.childrenWidth, nextProps.childrenMargin) &&
    // nextProps.children.length > 0) {
    //   console.log(nextProps);
    //   this.setChildren(nextProps);
    // }
    // console.log(this.props, nextProps);
    if(nextProps !== this.props && nextProps.children.length > 0 && !this.state.teste) {
      this.setState({teste: true}, () => {
        this.setChildren(nextProps);
      });
      // Object.keys(nextProps).map(item => {
      //   if(nextProps[item] !== this.props[item]) {
      //     console.log(item, nextProps[item])
      //   }
      // })
      // console.log(nextProps);
      // if(nextProps.children.length > 0) {
      //   console.log(nextProps);
      // }
    }
  }

  onResize = () => {
    if(this.state.teste) {
      this.setState({
        scroll: 0,
        scrollReverse: 0,
        canScroll: true,
        children: [],
        animations_normal: [],
        animations_reverse: [],
        multipliers_normal: [],
        multipliers_reverse: [],
        childrenWidth: 0,
        childrenMargin: 0,
        totalSize: 0,
        pressed: false,
        positions: [],
        classes: [],
        currentMainItem: 0,
        childrenSize: 0,
        closed: false
      }, () => 
      this.setChildren(this.props));
    };
  }

  componentWillUnmount() {
    this.setState({closed: true});
    window.removeEventListener('resize', this.onResize);
  }

  setChildren = (props) => {
    let childrenSize = (props.axis === 'y' ? props.childrenHeight : props.childrenWidth) + props.childrenMargin;
    let screenTotalSize = props.axis === 'y' ? window.innerHeight :  window.innerWidth;
    let initialTotalSize = childrenSize * props.children.length;
    let children = [];
    if(screenTotalSize > initialTotalSize) {
        for(var i = 0; i > -1; i++) {
            children.push(i % this.props.children.length);
            if(childrenSize * i >= (screenTotalSize * 2)) {
                break;
            }
        }
    } else {
        for(var i = 0; i < (this.props.children.length * 2); i++) {
          children.push(i % this.props.children.length);
        }
    }
    let totalSize = childrenSize * children.length;
    let animations_normal = [];
    let animations_reverse = [];
    let multipliers_normal = [];
    let multipliers_reverse = [];
    let classes = [];
    const results = children.map(async (item, i) => {
        animations_normal.push(gsap.timeline());
        animations_reverse.push(gsap.timeline());
        multipliers_normal.push(0);
        multipliers_reverse.push(0);
        classes.push('');
    });

    Promise.all(results).then(() => {
        this.setState({
        children,
        animations_normal,
        animations_reverse,
        multipliers_normal,
        multipliers_reverse,
        totalSize,
        childrenSize,
        classes,
        scroll: this.props.align === 'beginning' ? 0 : (totalSize/2) - (this.props.childrenWidth/2),
        scrollReverse: this.props.align === 'beginning' ? 0 : ((totalSize/2) - (this.props.childrenWidth/2)) - (this.props.childrenWidth/2) - (this.props.childrenMargin/2) - (this.props.childrenWidth*2) - (this.props.childrenMargin*2)
        }, () => {
            this.initialValues();
        });
    });
  }

  initialValues = () => {
    this.state.animations_normal.map((item, i) => {
      let scroll = this.state.scroll + (this.state.childrenSize*i);
      let scrollReverse = this.state.scrollReverse + (this.state.childrenSize*i);
      this.state.animations_normal[i].to(".item_"+this.props.childrenClass+'normal'+i, {duration: 0, [this.props.axis]: scroll, ease: "power4"});
      this.state.animations_reverse[i].to(".item_"+this.props.childrenClassverse+'reverse'+i, {duration: 0, [this.props.axis]: scrollReverse, ease: "power4"});
    });
    this.setState({totalSize: this.state.childrenSize * this.state.children.length}, () => {
      // let num = this.props.axis === 'x' ? (window.innerWidth/2 - this.props.childrenWidth/2) : (window.innerHeight/2 - this.props.childrenHeight/2);
      // let numReverse = this.props.axis === 'x' ? (window.innerWidth/2 - this.props.childrenWidth) : (window.innerHeight/2 - this.props.childrenHeight);
      this.setState({scroll: this.state.scroll-this.state.totalSize, scrollReverse: this.state.scrollReverse+this.state.totalSize}, () => {
        let num = 0;
        let interval = setInterval(() => {
           if(num > this.state.children.length-1) {
            clearInterval(interval);
            if(this.props.onFirstScrollStopped) {
              this.props.onFirstScrollStopped();
            }
           } else {
            num++;
            if(this.props.order.includes('normal')) {
              this.onUpdateItemsPosition(1, false, 'normal');
            }
            if(this.props.order.includes('reverse')) {
              this.onUpdateItemsPosition(1, false, 'reverse');
            }
           }
        }, 100);
      });
    });
  }

  wheel = (e) => {
    if(this.state.canScroll) {
      this.setState({canScroll: false}, () => {
        setTimeout(() => {
          this.setState({canScroll: true});
        })
      });
      this.setState({scroll: e.deltaY > 0 ? this.state.scroll-160 :  this.state.scroll+160,
      scrollReverse: e.deltaY > 0 ? this.state.scrollReverse+160 :  this.state.scrollReverse-160}, () => {
        if(this.props.order.includes('normal')) {
          this.onUpdateItemsPosition(1, false, 'normal');
        }
        if(this.props.order.includes('reverse')) {
          this.onUpdateItemsPosition(1, false, 'reverse');
        }
      });
    }
  };

  onUpdateItemsPosition = (duration, repeat, direction) => {
    if(!this.state.closed) {
    let scrollState = direction === 'normal' ? this.state.scroll : this.state.scrollReverse;
    let scrollStateName = direction === 'normal' ? 'scroll' : 'scrollReverse';
    let multiplierState = [...this.state['multipliers_'+direction]];
    let positions = [];
    let size = this.props.axis === 'x' ? this.props.childrenWidth : this.props.childrenHeight;
    let aditionalValue = this.props.align === 'beginning' ? 0 : (this.state.totalSize/2) - (size/2);
    this.state['animations_'+direction].map((item, i) => {
      this.state['animations_'+direction][i].clear();
    });
    this.state.children.map((i, index) => {
      positions.push( (this.state.totalSize/2) - (this.state.childrenSize/2) -((index * this.state.childrenSize) + (this.state.totalSize * this.state['multipliers_'+direction][index])) );
    });
    this.state.children.map((i, index) => {
      let scroll = scrollState + (index * this.state.childrenSize) + (this.state.totalSize * this.state['multipliers_'+direction][index]);
        this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: duration, [this.props.axis]: scroll, ease: "power3", onUpdate: () => {
          if(document.querySelector('.item_'+this.props.childrenClass+direction+index) !== null) {
          // console.log('.item_'+this.props.childrenClass+direction+index, document.querySelector('.item_'+this.props.childrenClass+direction+index));
          let x = this.props.axis === 'x' ?
          parseFloat(document.querySelector('.item_'+this.props.childrenClass+direction+index).style.transform.replace('translate(','').replace('translate3d(','').split('px')[0]) :
          parseFloat(document.querySelector('.item_'+this.props.childrenClass+direction+index).style.transform.replace('translate(','').replace('translate3d(','').split('px')[1].replace(', ',''));
          let currentScroll = x - (index * this.state.childrenSize) - (this.state.totalSize * this.state['multipliers_'+direction][index]);
          if(currentScroll <= aditionalValue -(index * this.state.childrenSize) - (this.state.totalSize * multiplierState[index]) - (this.state.childrenSize * 3)) {
              multiplierState[index] = multiplierState[index]+1;
              this.setState({['multipliers_'+direction]: multiplierState},() => {
              let scroll = currentScroll + (index * this.state.childrenSize) + (this.state.totalSize * multiplierState[index]);
                this.state['animations_'+direction][index].clear();
                this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: 0, [this.props.axis]: scroll,
                onComplete: () => {
                    if(!this.props.ajust) {
                      this.state['animations_'+direction][index].clear();
                      let scroll = scrollState + (index * this.state.childrenSize) + (this.state.totalSize * multiplierState[index]);
                      this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: duration, [this.props.axis]: scroll, ease: "power3"});
                    }
                }});
              });
          } else { 
              if(currentScroll >= aditionalValue -(index * this.state.childrenSize) - (this.state.totalSize * (multiplierState[index]-1)) - (this.state.childrenSize * 3)) { 
                  multiplierState[index] = multiplierState[index]-1;
                  this.setState({['multipliers_'+direction]: multiplierState},() => {
                    let scroll = currentScroll + (index * this.state.childrenSize) + (this.state.totalSize * multiplierState[index]);
                    this.state['animations_'+direction][index].clear();
                    this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: 0, [this.props.axis]: scroll,
                    onComplete: () => {
                        if(!this.props.ajust) {
                          this.state['animations_'+direction][index].clear();
                          let scroll = scrollState + (index * this.state.childrenSize) + (this.state.totalSize * multiplierState[index]);
                          this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: duration, [this.props.axis]: scroll, ease: "power3"});
                        }
                    }});
                  });
              }
          };
          if(this.props.ajust) {
            this.state['animations_'+direction][index].clear();
            let scroll = scrollState + (index * this.state.childrenSize) + (this.state.totalSize * multiplierState[index]);
            this.state['animations_'+direction][index].to(".item_"+this.props.childrenClass+direction+index, {duration: duration, [this.props.axis]: scroll, ease: "power3",
            onComplete: () => {
              if(repeat) {
                for(var i = 0; i < positions.length; i++) {
                  let begging = direction === 'normal' ?
                  positions[i] + (size/2) + this.props.childrenMargin
                  : positions[i] + (size/2) + this.props.childrenMargin;
                  let end = direction === 'normal' ? 
                  positions[i] - (size/2) - this.props.childrenMargin
                  : positions[i] - (size/2) - this.props.childrenMargin;
                  if(scrollState <= begging && scrollState >= end) {
                    let num = direction === 'normal' ? size/2 : size;
                    if(direction === 'normal') {
                      this.setState({[scrollStateName]: positions[i] + (this.props.childrenMargin/2)}, () => {
                        this.onUpdateItemsPosition(1, false, direction);
                        if(this.props.onMainItemChange && this.state.currentMainItem !== i) {
                          this.props.onMainItemChange(i);
                          this.setState({currentMainItem: i});
                        }
                      });
                    } else {
                      this.setState({scrollReverse: positions[i] - (this.props.childrenWidth/2)}, () => {
                        this.onUpdateItemsPosition(1, false, direction);
                      });
                    }
                    break;
                  }
                }
              }
            }});
          }
        }
        }});
    });
    if(direction === 'normal' && window.innerWidth > 700) {
      for(var i = 0; i < positions.length; i++) {
        let begging = positions[i] + (window.innerWidth/2);
        let end = positions[i] - (this.props.childrenWidth/3);
        if(scrollState <= begging && scrollState >= end) {
            if(this.props.onMainItemChange && this.state.currentMainItem !== i) {
              this.props.onMainItemChange(i);
              this.setState({currentMainItem: i});
            }
            break;
        }
      }
    }
    }
  }

  touchstart = (e) => {
    let event = e.touches ? e.touches[0] : e;
    this.setState({touch: event['client'+this.props.axis.toUpperCase()]});
    this.setState({pressed: true});
  };

  touchmove = (e) => {
    if(this.state.pressed || e.touches) {
      if(this.props.updatePressed && window.innerWidth >= 1200) {
        this.props.updatePressed(true);
      };
      let num = 0;
      let event = e.touches ? e.touches[0] : e;
      num = window.innerWidth > 700 ? this.state.touch - event['client'+this.props.axis.toUpperCase()] : (this.state.touch - event['client'+this.props.axis.toUpperCase()])*3;
      this.setState({touch: event['client'+this.props.axis.toUpperCase()]});
      this.setState({scroll: this.state.scroll-(num), scrollReverse: this.state.scrollReverse+(num)}, () => {
        if(this.props.order.includes('normal')) {
          this.onUpdateItemsPosition(1, window.innerWidth <= 700, 'normal');
        }
        if(this.props.order.includes('reverse')) {
          this.onUpdateItemsPosition(1, window.innerWidth <= 700, 'reverse');
        }
      });
    }
  };

  touchstop = (click) => {
    this.setState({pressed: false});
    if(this.props.updatePressed && click) {
        this.props.updatePressed(false);
    }
  }

  onPressed = e => {
    if(this.props.updatePressed && e.touches) {
        this.setState({pressed: false});
        this.props.updatePressed(false);
    };
  }

  render() {
    return (
      <div className={this.state.pressed ? 'slider-container text-selection-diabled' : 'slider-container'}
      onWheel={this.wheel}
      onMouseDown={this.touchstart}
      onTouchStart={this.touchstart}
      onMouseMove={this.touchmove}
      onTouchMove={this.touchmove}
      onMouseUp={() => this.touchstop(false)}
      onClick={() => this.touchstop(true)}
      onMouseEnter={this.touchstop} style={{
        ...this.props.style,
        flexDirection: this.props.axis === 'x' ? 'column' : 'row'
    }}>
          {
            this.state.totalSize > 0 ?
            <div style={this.props.axis === 'y' ? {
              ...this.props.slidersContainerStyle,
              height: this.state.totalSize+'px',
              transition: 'all 0.8s cubic-bezier(0, 0.83, 0.25, 1.03) 0s'
          }: {
              ...this.props.slidersContainerStyle,
              width: this.state.totalSize+'px',
              transition: 'all 0.8s cubic-bezier(0, 0.83, 0.25, 1.03) 0s'
          }}>
          {
              this.props.order.map((direction, index) => {
                  const children = React.Children.toArray(this.props.children);
                  return (
                      <div key={index} className={this.props.containerClass ? 'slider '+this.props.containerClass : 'slider'} style={this.props.axis === 'y' ? {
                          width: this.props.childrenWidth+'px',
                          height: '100%',
                          overflow: 'hidden',
                          transition: '0.7s',
                          marginBottom: index !== this.props.order.length - 1 ? this.props.distanceBetweenRows : 0
                      }: {
                          width: '100%',
                          height: this.props.childrenHeight+'px',
                          overflow: 'hidden',
                          transition: '0.7s',
                          marginBottom: index !== this.props.order.length - 1 ? this.props.distanceBetweenRows : 0
                      }}>
                          {
                              this.state.children.map((i, index) => {
                                  let className = children[i].props.className ? ' '+children[i].props.className : '';
                                  return React.cloneElement(children[i], {
                                      key: index,
                                      id: 'item'+direction+index,
                                      className: this.state.classes[i] + ' item_'+this.props.childrenClass+direction+index+' '+className,
                                      onTouchStart: this.onPressed,
                                      style: {
                                          ...children[i].props.style,
                                          width: this.props.childrenWidth+'px',
                                          height: this.props.childrenHeight+'px',
                                          position: 'absolute',
                                          left: 0
                                      }
                                  })
                              })
                          }
                      </div>
                  )
              })
          }
        </div> :
        <div></div>
          }
    </div>
    )
  }
}


export default Slider;
