import React, { Component } from 'react';
import Home from './Home'
import Projects from './Projects';
import About from './About';
import Galery from './Galery';
import Downloads from './Downloads';
import ContactInfo from './ContactInfo';
import NotFound from './NotFound';
import PageContainer from '../shared/PageContainer';

export default class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            home: false,
            projects: false,
            about: false,
            gallery: false,
            // sources: false,
            contact: false,
            currentPage: '',
            closing: false,
            loading: true,
            notFound: false,
            pages: [
                'home',
                'projects',
                'about',
                'gallery',
                // 'sources',
                'contact',
                'notFound'
            ]
        }
    }

    componentDidMount() {
        let location = this.props.location.pathname.substring(1) ? this.props.location.pathname.substring(1) : 'home';
        location = location.indexOf('/') > -1 ? location.substring(0, location.indexOf('/')) : location;
        this.setState({[location]: true});
        this.renderPage(this.props);
        setTimeout(() => {
            this.setState({loading: false});
        }, 2000);
    }

    componentWillReceiveProps(nextProps) {
        this.renderPage(nextProps);
    }

    renderPage = (props) => {
        if(!props.match.params.projectId) {
            let location = props.location.pathname.substring(1) ? props.location.pathname.substring(1) : 'home';
            location = location.indexOf('/') > -1 ? location.substring(0, location.indexOf('/')) : location;
            if(!this.state.pages.includes(location)) {
                this.setState({notFound: true});
                location = 'notFound';
            } else {      
                if(!this.state[location]) {
                    this.setState({[location]: true});
                }
            }
            this.setState({closing: true});
            this.setState({currentPage: location});
            setTimeout(() => {
                Object.keys(this.state).map((key, index) => {
                    if(key !== location && key !== 'pages') {
                        this.setState({[key]: false});
                    }
                });
                this.setState({closing: false});
            }, location === 'notFound' ? 100 : 800);
        }
    }

    render() {

        const renderHome = () => {
            if(this.state.home && this.props.loaded) {
                return <PageContainer style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'home' ? 0 : 1
                }}><Home/></PageContainer>
            }
        }
        const renderProjects = () => {
            if(this.state.projects && this.props.loaded) {
                return <PageContainer content={this.props.content} page='projects' style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'projects' ? 0 : 1
                }}><Projects loaded={this.props.loaded}/></PageContainer>
            }
        }

        const renderGalery = () => {
            if(this.state.gallery && this.props.loaded) {
                return <PageContainer page='gallery' content={this.props.content} style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'gallery' ? 0 : 1
                }}><Galery/></PageContainer>
            }
        }

        const renderAbout = () => {
            if(this.state.about && this.props.loaded) {
                return <PageContainer content={this.props.content} page='about' style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'about' ? 0 : 1
                }}><About loaded={this.props.loaded}/></PageContainer>
            }
        }

        // const renderDownloads = () => {
        //     if(this.state.sources && this.props.loaded) {
        //         return <PageContainer content={this.props.content} page='sources' style={{
        //             height: '100%',
        //             transition: '0.8s',
        //             opacity: this.state.closing && this.state.currentPage !== 'sources' ? 0 : 1
        //         }}><Downloads loaded={this.props.loaded}/></PageContainer>
        //     }
        // }

        const renderContactInfo = () => {
            if(this.state.contact && this.props.loaded) {
                return <PageContainer content={this.props.content} page='contact_info' style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'about' ? 0 : 1
                }}><ContactInfo loaded={this.props.loaded}/></PageContainer>
            }
        }

        const renderNotFound = () => {
            if(this.state.notFound && this.props.loaded) {
                return <PageContainer content={this.props.content} page='not_found' style={{
                    height: '100%',
                    transition: '0.8s',
                    opacity: this.state.closing && this.state.currentPage !== 'about' ? 0 : 1
                }}><NotFound loaded={this.props.loaded}/></PageContainer>
            }
        }

        return (
            <div className='all-content' style={{
                height: '100%'
            }}>
                {renderHome()}
                {renderProjects()}
                {renderGalery()}
                {renderAbout()}
                {/* {renderDownloads()} */}
                {renderContactInfo()}
                {renderNotFound()}
            </div>
        )
    }
}
