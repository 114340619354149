import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from './Slider1';

export default class MenuList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            scroll: 0,
            teste: 0,
            multiplierIndex0: 0,
            multiplierIndex1: 0,
            multiplierIndex2: 0,
            multiplierIndex3: 0,
            multiplierIndex4: 0,
            multiplierIndex0reverse: 0,
            multiplierIndex1reverse: 0,
            multiplierIndex2reverse: 0,
            multiplierIndex3reverse: 0,
            multiplierIndex4reverse: -1,
            started: 0,
            stopped: 0,
            scrolling: false,
            initialScrolling: false,
            menuItens: [],
            listItemHeight: 180,
            positions: [],
            positionsEnd: [],
            totalHeight: 0,
            initialSubstractor: 0,
            pressed: false,
            listItemWidth: 0
        }
    }

    number = 0;

    componentDidMount() {
        setTimeout(() => {
            this.setState({menuItens: this.props.items}, () => {
                    this.checkScreenSize();
                    window.addEventListener('resize', this.checkScreenSize);
                });
        });
    }

    // setInitialNumber = () => {
    //     let total = this.state.totalHeight * 2;
    //     let y = 0;
    //     for(let i = 0; i <= total; i+=0.5) {
    //         y = y + i;
    //         // console.log(total, y);
    //         if(y >= total) {
    //             this.number = i;
    //             console.log(total, y);
    //             break;
    //         }
    //     }
    // }

    // componentWillReceiveProps(nextProps) {
    //     if(!this.props.isOpend && nextProps.isOpend) {
    //         this.setInitialNumber();
    //         this.setState({
    //             initialSubstractor: (window.innerHeight / 16) / 97,
    //             scroll: 0, initialScrolling: true}, () => {
    //             this.initialScroll();
    //         });
    //     }
    // }

    checkScreenSize = () => {
        if(window.innerWidth > 1400) {
            this.setState({listItemHeight: 360, listItemWidth: window.innerWidth - (window.innerWidth/3)});
        } else if(window.innerWidth > 1100) {
            this.setState({listItemHeight: 200, listItemWidth: window.innerWidth - (window.innerWidth/3)});
        } else if(window.innerWidth > 900) {
            this.setState({listItemHeight: 180, listItemWidth: window.innerWidth/100*60});
        } else if(window.innerWidth > 700) {
            this.setState({listItemHeight: 150, listItemWidth: window.innerWidth/100*57});
        } else {
            this.setState({listItemHeight: 80, listItemWidth: window.innerWidth - (window.innerWidth/5)});
        }
    }

    // initialScroll = () => {
    //     this.number -= 0.5;
    //     this.setState({scroll: this.state.scroll-this.number}, () => this.updateItemsPosition());
    //     if (this.number <= 0) {
    //         this.number = 4;
    //         setTimeout(() => {this.setState({initialScrolling: false});});
    //     } else {
    //         window.requestAnimationFrame(this.initialScroll);
    //     }
    // };

    // countUp = () => {
    //     window.cancelAnimationFrame(this.countDown);
    //     this.setState({scroll: this.state.scroll-this.number});
    //     this.updateItemsPosition();
    //     if (this.state.started !== this.state.stopped) {
    //         window.requestAnimationFrame(this.countUp);
    //     } else {
    //         setTimeout(() => {
    //             this.setState({scrolling: false});
    //         }, 200);
    //     }
    // };

    // countDown = () => {
    //     window.cancelAnimationFrame(this.countUp);
    //     this.setState({scroll: this.state.scroll+this.number});
    //     this.updateItemsPosition();
    //     if (this.state.started !== this.state.stopped) {
    //         window.requestAnimationFrame(this.countDown);
    //     } else {
    //         setTimeout(() => {
    //             this.setState({scrolling: false});  
    //         }, 200);
    //     }
    // };

    // updateItemsPosition = () => {
    //     this.state.menuItens.map((position, index) => {
    //         if(this.state.scroll <= -((index+1) * this.state.listItemHeight) - (this.state.totalHeight * this.state['multiplierIndex'+index]) - this.state.listItemHeight) {
    //             this.setState({['multiplierIndex'+index]: this.state['multiplierIndex'+index]+1 });
    //         } else {
    //             if(this.state.scroll >= -((index+1) * this.state.listItemHeight) - (this.state.totalHeight * (this.state['multiplierIndex'+index]-1))) {
    //                 this.setState({['multiplierIndex'+index]: this.state['multiplierIndex'+index]-1 });
    //             }
    //         }
    //     });
    // }

    // onWheel = (e) => {
    //     if (!this.state.initialScrolling) {
    //         window.cancelAnimationFrame(this.countUp);
    //         window.cancelAnimationFrame(this.countDown);
    //         this.setState({scrolling: true});
    //         if (e.deltaY > 0) {

    //             this.setState({started: this.state.started+1});
    //             this.countUp();

    //             setTimeout(() => {
    //                 this.setState({stopped: this.state.stopped+1});
    //             }, 250);
    //         } else {

    //             this.setState({started: this.state.started+1});
    //             this.countDown();

    //             setTimeout(() => {
    //                 this.setState({stopped: this.state.stopped+1});
    //             }, 250);
    //         }
    //     }
    // }
    
    render() {
        return (
            <div className='menu-container'>
               {
                   this.state.menuItens.length > 0 ?
                   <Slider
                   order={['normal']}
                   childrenClass='menu-item'
                   childrenWidth={this.state.listItemWidth} 
                   containerClass='menu-items'
                   align='beginning'
                   indexPlusDown={0}
                   indexPlusUp={1}
                   initialScrollMultiplier={2}
                   updatePressed={e =>{this.setState({pressed: e})}}
                   childrenHeight={this.state.listItemHeight} 
                   childrenMargin={0} 
                   axis='y'>
                       {
                           this.state.menuItens.map((item, index) => {
                               return (
                                   <div key={index} onClick={this.toggleMenu}
                                   className='menu-item'
                                   style={{
                                       pointerEvents: !this.state.pressed ? 'all' : 'none',
                                       height: this.state.listItemHeight + 'px'
                                   }}>
                                       <Link to={'/'+item.link}><span>{item.name}</span><span>0{index+1}</span></Link>
                                   </div>
                               )  
                           })
                       }
                   </Slider> :
                   <div></div>
               }
            </div>
        )
    }
}
