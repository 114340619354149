import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import Background from '../images/home_background.jpg'
import { ReactComponent as HomePhrase } from '../images/HomePhrase.svg'
import { ReactComponent as Arrow } from '../images/ArrowRight.svg'

export default function Home() {
    return (
        <div className='session home'>
            <div className='background-home'>
                <HomePhrase className='title'/>
                <div className='about'>
                    <h2>VISUAL DESIGNER</h2>
                    <Link to='/about'>
                        <Arrow className='arrow'/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
