import React, { Component } from 'react'
import Navigation from './navigation/Navigation'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss';

export default class App extends Component {
  render() {
    return (
      <Router>
        <Navigation/>
      </Router>
    )
  }
}

