import React, { Component } from 'react'

export default class OpenedProjectType2 extends Component {
    render() {

        const { project, className, next } = this.props;

        const renderNextText = () => {
            if(!this.props.isTheLast) {
                return (
                    <div className='next mr-5'>
                        <h1>{next}</h1>
                    </div>
                )
            }
            return
        }

        return (
            <div id={project.id} className={className}>
                <div className='project-img img-lg pl-50'>
                    <div className='project-img img-lg'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img1}/>
                    </div>
                </div>
                <div className='project-text pl-5 pr-5'>
                    <p dangerouslySetInnerHTML={{__html: project.text1}}></p>
                </div>
                <div className='project-img img-md mr'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img2}/>
                    </div>
                </div>
                <div className='project-img img-md mr'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img3}/>
                    </div>
                </div>
                <div className='project-img img-md mr'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img4}/>
                    </div>
                </div>
                <div className='project-img img-md mr'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img5}/>
                    </div>
                </div>
                <div className='project-img img-md'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img6}/>
                    </div>
                </div>
                <div className='project-text ml-5 mr-5'>
                    <p dangerouslySetInnerHTML={{__html: project.text2}}></p>
                </div>
                <div className='project-img img-lg mr'>
                    <div className='project-img img-lg'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img7}/>
                    </div>
                </div>
                <div className='project-img img-md mr'>
                    <div className='project-img img-md'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img8}/>
                    </div>
                </div>
                <div className='project-img img-lg mr mr-last'>
                    <div className='project-img img-lg'>
                        <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + project.img9}/>
                    </div>
                </div>
                {renderNextText()}
            </div>
        )
    }
}
