import React, { Component } from 'react'
import { Switch, Route, withRouter, Link } from 'react-router-dom'
import Home from './Home'
import SideMenu from '../shared/SideMenu';
import Footer from '../shared/Footer';
import Projects from './Projects';
import Loader from './Loader';
import About from './About';
import Galery from './Galery';
import Content from './Content';
import { ReactComponent as Logo } from '../images/logo.svg';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showFixedElements: true,
            menuRenderStatus: false,
            content: null,
            content_pt: null,
            content_en: null
        }
    }

    getContent = async () => {
        const ptContent = await fetch(process.env.PUBLIC_URL + '/content/pt.json');
        const ptContentJson = await ptContent.json();
        const enContent = await fetch(process.env.PUBLIC_URL + '/content/en.json');
        const enContentJson = await enContent.json();
        
        return {content_pt: ptContentJson, content_en: enContentJson};
    }

    componentDidMount() {
        this.getContent().then(res => {
            this.setState({content_pt: res.content_pt, content_en: res.content_en});
            setTimeout(() => {
                this.setState({loading: false});
            }, 2000);
            this.renderFixedElements(this.props);
            this.props.history.listen(() => this.renderFixedElements(this.props));
            if(this.logo.clientHeight > this.sideMenu.getBoundingClientRect().height) {
                this.sideMenu.style.top = this.sideMenu.getBoundingClientRect().top + ((this.logo.clientHeight - this.sideMenu.getBoundingClientRect().height)/2) + 'px';
            } else {
                this.logo.style.top = this.logo.clientHeight + ((this.sideMenu.getBBox().height - this.logo.clientHeight)/2) + 'px';
            }
            this.langBtn.style.top = this.langBtn.getBoundingClientRect().top + ((this.logo.clientHeight - this.langBtn.getBoundingClientRect().height)/2) + 'px';
            if(localStorage.getItem('language')) {
                this.setState({content: this.state['content_'+localStorage.getItem('language')]});
            } else {
                localStorage.setItem('language', 'pt');
                this.setState({content: res.content_pt});
            }
        });
   }

    renderFixedElements = (props) => {
        this.setState({showFixedElements: !props.location.pathname.indexOf('/projects/') == 0});
    }

    componentWillReceiveProps(nextProps) {
        this.renderFixedElements(nextProps);
    }

    changeLanguage(lang) {
        localStorage.setItem('language', lang);
        this.setState({content: this.state['content_'+lang]});
    }

    render() {

        const loader = () => {
            if(this.state.loading) {
                return <Loader />
            }
        }

        const languageButtons = () => {
            return (
                <div ref={ref => this.langBtn = ref} className={this.state.showFixedElements ? 'language-btns' : 'language-btns black'}>
                    <p onClick={() => this.changeLanguage('pt')} style={{
                        fontWeight: this.state.content === this.state.content_pt ? 'bold' : 'inherit'
                    }}>PT</p>
                    <p onClick={() => this.changeLanguage('en')} style={{
                        fontWeight: this.state.content === this.state.content_en ? 'bold' : 'inherit'
                    }}>EN</p>
                </div>
            )
        }

        const menu = () => {
            return <SideMenu style={{
                opacity: this.state.showFixedElements ? 1 : 0,
                pointerEvents: this.state.showFixedElements ? 'all' : 'none'
            }} content={this.state.content} setRef={ref => this.sideMenu = ref} menuColor={'white'} renderStatus={menuRenderStatus => this.setState({menuRenderStatus})}/>
        }

        const socialMedias = () => {
            if(this.state.showFixedElements && (this.props.location.pathname !== "/projects" || window.innerWidth > 700 || this.state.menuRenderStatus)) {
                return (
                    <div className='social-medias'>
                        <a href='https://www.behance.net/brunocesarsouzarocha' target='_blank'>BE</a>
                        <a href='https://dribbble.com/brunocdsr' target='_blank'>DR</a>
                        <a href='https://www.linkedin.com/in/brunocesar-souzarocha/' target='_blank'>IN</a>
                    </div>
                )
            }
        }

        return (
            <div className='session' style={{
                height: window.innerHeight+'px'
            }}>
                {loader()}
                {languageButtons()}
                {menu()}
                <Link to='/'>
                    <Logo ref={ref => this.logo = ref} style={{height: 'inherit'}} className={this.state.showFixedElements ? 'home-icon' : 'home-icon black'}/>
                </Link>
                {socialMedias()}
                <Switch>
                    <Route path='/' exact render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />} />
                    <Route exact path='/projects/:projectId' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route exact path='/projects' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route exact path='/about' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route exact path='/gallery' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route exact path='/sources' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route exact path='/contact' render={(props) => <Content {...props} content={this.state.content} loaded={!this.state.loading} />}/>
                    <Route render={(props) => <Content {...props} content={this.state.content} notFound={true} loaded={!this.state.loading} />}/>
                </Switch>
                <Footer/>
            </div>
        )
    }
}

export default withRouter(Navigation)
