import React, { Component } from 'react';
import contentPt from '../content/pt.json';

export default class PageContainer extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    // componentDidMount() {
    //     console.log(this.props.content)
    // }

    render() {
        return (
            <div className='session' style={this.props.style}>
                {React.cloneElement(this.props.children, {
                    content: this.props.content ? this.props.content.pages[this.props.page] : null
                })}
            </div>
        )
    }
}
