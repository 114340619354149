import React, { Component } from 'react'
import Slider from '../shared/Slider1';
import { withRouter } from 'react-router-dom';
import ProjectsOpened from './ProjectsOpened';

class Projects extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            projectId: null,
            closing: false,
            showAllProjects: false,
            projects: [],
            titlesClasses: [],
            hoveredProject: '',
            selectedType: 'all',
            currentShownProject: 1,
            pressed: false,
            opacity: '1',
            pointerEvents: 'all',
            initialScrolling: true,
            childrenWidth: 600,
            childrenHeight: 280,
            margin: 200
        }
    }

    componentDidMount() {
        this.setValues(this.props);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setValues(this.props);
    }

    setValues = (props) => {
        this.setState({projects: props.content.list_items}, () => {
            if(this.props.loaded == true) {
                this.setState({projectId: parseInt(this.props.match.params.projectId)});
                let result = this.state.projects.filter(x => x.id === parseInt(this.props.match.params.projectId));
                if(result.length > 0) {
                    this.changeHoveredProject(result[0].name);
                }
            }
            this.checkScreenSize();
        });
    }

    checkScreenSize = () => {
        if(window.innerWidth > 1400) {
            this.setState({childrenWidth: 750, childrenHeight: 270, margin: 280}, () => 
            this.setState({titlesClasses: Array.from({length: this.state.projects.length}).fill('title')}, () => this.updateTitlesClass()));
        } else if(window.innerWidth > 700) {
            this.setState({childrenWidth: 600, childrenHeight: 230, margin: 230}, () => 
            this.setState({titlesClasses: Array.from({length: this.state.projects.length}).fill('title')}, () => this.updateTitlesClass()));
        } else {
            this.setState({childrenWidth: window.innerWidth/100*90, childrenHeight: window.innerHeight/100*35, margin: window.innerWidth/100*20}, () => 
            this.setState({titlesClasses: Array.from({length: this.state.projects.length}).fill('title')}, () => this.updateTitlesClass()));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.content !== nextProps.content) {
            // this.setValues(nextProps);
            this.setState({projects: nextProps.content.list_items}, () => {
                // if(this.props.loaded == true) {
                //     this.setState({projectId: parseInt(this.props.match.params.projectId)});
                //     let result = this.state.projects.filter(x => x.id === parseInt(this.props.match.params.projectId));
                //     if(result.length > 0) {
                //         this.changeHoveredProject(result[0].name);
                //     }
                // }
                // this.checkScreenSize();
            });
        }
        this.setState({pressed: false});
        if((this.props.match.params.projectId && nextProps.match.params.projectId) || nextProps.match.params.projectId) {
            this.setState({projectId: parseInt(nextProps.match.params.projectId)});
        } else {
            this.changeHoveredProject('');
            this.setState({closing: true});
            this.setState({pointerEvents: 'none'});
            setTimeout(() => {
                this.setState({opacity: '1'});
            }, 400);
            setTimeout(() => {
                this.setState({pointerEvents: 'all'});
            }, 700);
            setTimeout(() => {
                this.setState({closing: false});
                this.setState({projectId: parseInt(nextProps.match.params.projectId)});
            }, 800);
        }
    }

    updatePressed = e => {
        this.setState({pressed: e});
    }

    onFirstScrollStopped = () => {
        this.setState({initialScrolling: false});
    }

    toggleProject = (project) => {
        if(!this.state.pressed && !this.state.initialScrolling) {
            this.setState({opacity: '0'});
            this.props.history.push('/projects/'+project.id);
            this.changeHoveredProject(project.name);
        }
    }

    changeHoveredProject = (hoveredProject) => {
        this.setState({hoveredProject});
    }

    updateTitlesClass = (index) => {  
        index = !index ? 0 : index;
        this.setState({currentShownProject: (index + 1) % this.state.projects.length === 0 ? this.state.projects.length : (index + 1) % this.state.projects.length});
        let titlesClasses = [...this.state.titlesClasses];  
        let activeTitles = [];
        for (var i = index; i < index + parseInt(window.innerWidth/(this.state.childrenWidth+this.state.margin)); i++) {
            activeTitles.push(i);
        }
        if(activeTitles.length === 0) {
            activeTitles.push(index);
        }
        activeTitles.map((i, index) => {
            if(i >= this.state.titlesClasses.length) {
                activeTitles[index] = i - this.state.titlesClasses.length
            }
        });
        titlesClasses.map((p, i) => {
            if (activeTitles.includes(i)) { 
                titlesClasses[i] = 'title selected';
            } else {
                titlesClasses[i] = 'title';
            }
        });
        this.setState({titlesClasses});
    }

    onShowAllProjects = () => {
        this.setState({showAllProjects: !this.state.showAllProjects});
        this.setState({selectedType: 'all'});
        this.setState({hoveredProject: ''});
    }

    changeProjectType = (selectedType) => {
        this.setState({selectedType});
    }

    render() {

        const renderDetais = () => {
            if (this.state.projectId) {
                return <ProjectsOpened
                        next={this.props.content.next}
                        toggleProject={this.toggleProject}
                        selectedProject={this.state.projectId}
                        projects = {this.state.projects}
                        history={this.props.history} 
                        closing={this.state.closing} />
            }
        }

        const renderProjectTitle = (project) => {
            if(!this.state.showAllProjects) {
                return (
                    <svg viewBox="0 0 10 2" className={this.state.titlesClasses[project.id-1]}>
                        <text x="0" y="1.3" textAnchor="left" fontWeight="bold"
                        fontSize="1.5" fill="none" strokeWidth=".015" stroke="#fff" 
                        fontFamily="sans-serif">{project.name}</text>
                    </svg>
                )
            }
        }

        const renderHoveredProjectTitle = () => {
            if(this.state.hoveredProject) {
                return (
                    <h1 className={!this.state.projectId ? 'selected-project' : 'selected-project black'}>{this.state.hoveredProject}</h1>
                )
            }
        }

        const projectsProgressBar = () => {
            if(!this.state.showAllProjects) {
                return (
                    <div className='counter'>
                        <div className='progress-bar'>
                            <div className='bar' style={{
                                width: this.state.currentShownProject*100/this.state.projects.length + '%'
                            }}></div>
                        </div>
                        <p>{this.state.currentShownProject + '/' + this.state.projects.length}</p>
                    </div>
                )
            }
        }

        const renderSelectProjectType = () => {
            if(this.state.showAllProjects) {
                return (
                    <div className='projects-types'>
                        <ul>
                            <li className={this.state.selectedType === 'all' ? 'selected' : ''} 
                            onClick={() => this.changeProjectType('all')}>all</li>
                            <li className={this.state.selectedType === 'branding' ? 'selected' : ''} 
                            onClick={() => this.changeProjectType('branding')}>Branding</li>
                            <li className={this.state.selectedType === 'digital' ? 'selected' : ''} 
                            onClick={() => this.changeProjectType('digital')}>Digital</li>
                            <li className={this.state.selectedType === 'campaign' ? 'selected' : ''} 
                            onClick={() => this.changeProjectType('campaign')}>Campaign</li>
                        </ul>
                    </div>
                )
            }
        }
        return (
            <div className='session projects'>
                {renderHoveredProjectTitle()}
                {
                    this.state.titlesClasses.length > 0 ? 
                    <Slider order={['reverse','normal','reverse']} 
                        style={{
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            transition: '0.5s',
                            opacity: this.state.opacity,
                            pointerEvents: this.state.pointerEvents
                        }} 
                        slidersContainerStyle={{
                            transform: this.state.showAllProjects ? 'scale(0.5)' : 'scale(1)'
                        }}
                        childrenClass='project-listed'
                        childrenWidth={this.state.childrenWidth} 
                        childrenHeight={this.state.childrenHeight} 
                        childrenMargin={this.state.margin} 
                        axis='x'
                        ajust={true}
                        indexPlusDown={2}
                        indexPlusUp={2}
                        onFirstScrollStopped={this.onFirstScrollStopped}
                        onMainItemChange={this.updateTitlesClass}
                        updatePressed={this.updatePressed}
                        distanceBetweenRows={this.state.showAllProjects ? (this.state.margin/100)*65 : '40vh'}>
                            {this.state.projects.map((project, index) => {
                            return (
                                <div className={this.state.selectedType !== 'all' && project.types.includes(this.state.selectedType)
                                ? 'project-listed text-selection-diabled not-selected' : 'project-listed text-selection-diabled'} 
                                onMouseOver={() => {if(this.state.showAllProjects) {this.changeHoveredProject(project.name)}}}
                                key={index} onClick={() => this.toggleProject(project)}>
                                    <div className='project-content'>
                                        {renderProjectTitle(project)}
                                        <div className='project-bg' style={{
                                            background: project.background_color
                                        }}>
                                            {/* <img src={process.env.PUBLIC_URL + '/images/projects/'+ project.folder.toLowerCase() + '/' + this.props.content.list_items[index].main_img}/> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider> 
                    : <div></div>
                }
                <h1 onClick={this.onShowAllProjects} style={{
                    transition: '0.5s',
                    opacity: this.state.initialScrolling ? '0' : '1' 
                }} className='all-projects-label'>{!this.state.showAllProjects ? this.props.content.all_projects : this.props.content.go_back}</h1>
                {projectsProgressBar()}
                {renderSelectProjectType()}
                {renderDetais()}
            </div>
        )
    }
}

export default withRouter(Projects)
