import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../images/ArrowRight.svg'

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentContent: -1,
            canScroll: true,
            scrolledStatus: 'down',
            border1: 0,
            border2: 0,
            border3: 0,
            border4: 0,
            sections: [
                {
                    name: 'me',
                    beginning: 0,
                    end: 1,
                    border: 0,
                    indexes: [0,1]
                },
                {
                    name: 'skills',
                    border: 0,
                    indexes: [2,3,4,5]
                },
                {
                    name: 'work',
                    border: 0,
                    indexes: [6,7]
                },
                {
                    name: 'courses and awards',
                    border: 0,
                    indexes: [8,9]
                }
            ],
            pressed: false,
            currentPosition: 0
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({currentContent: 0});
            this.renderBorder();
        }, !this.props.loaded ? 2000 : 1000);
        document.addEventListener('keydown', e => {
            // if(e.keyCode === 40) {

            // } else if(e.keyCode === 38) {
                
            // }
            this.onScroll(e);
        })
    }

    onScroll = (e) => {
        if(this.state.canScroll && this.state.currentContent >= 0 && this.state.currentContent <= 9) {
            this.setState({canScroll: false});
            if((e.deltaY > 0 || e.keyCode === 40) && this.state.currentContent < 9) {
                this.setState({scrolledStatus: ''});
                setTimeout(() => {
                    this.setState({scrolledStatus: 'down'});
                    this.setState({currentContent: this.state.currentContent+=1});
                    this.renderBorder();
                }, 200);
            } else if((e.deltaY < 0 || e.keyCode === 38) && this.state.currentContent > 0) {
                this.setState({scrolledStatus: ''});
                setTimeout(() => {
                    this.setState({scrolledStatus: 'up'});
                    this.setState({currentContent: this.state.currentContent-=1});
                    this.renderBorder();
                }, 200);
            }
            setTimeout(() => {this.setState({canScroll: true});}, 400);
        }
    }

    onTouchStart = (e) => {
        if(this.state.canScroll) {
            let event = e.touches ? e.touches[0] : e;
            this.setState({currentPosition: event.clientY}, () => this.setState({pressed: true}));
        }
    }

    onTouchMove = (e) => {
        let event = e.touches ? e.touches[0] : e;
        if(this.state.canScroll && this.state.currentContent >= 0 && this.state.currentContent <= 9 && this.state.pressed) {
            this.setState({canScroll: false});
            if(this.state.currentPosition - event.clientY > 0 && this.state.currentContent < 9) {
                this.setState({scrolledStatus: ''});
                setTimeout(() => {
                    this.setState({scrolledStatus: 'down'});
                    this.setState({currentContent: this.state.currentContent+=1});
                    this.renderBorder();
                }, 200);
            } else if(this.state.currentPosition - event.clientY < 0 && this.state.currentContent > 0) {
                this.setState({scrolledStatus: ''});
                setTimeout(() => {
                    this.setState({scrolledStatus: 'up'});
                    this.setState({currentContent: this.state.currentContent-=1});
                    this.renderBorder();
                }, 200);
            }
            setTimeout(() => {this.setState({canScroll: true});}, 300);
        }
    }

    onCancel = () => {
        this.setState({pressed: false});
    }

    counter = 0;

    renderBorder = () => {
        this.counter+=5;
        let sections = [...this.state.sections];
        this.state.sections.map((section, index) => {
            if(section.indexes.includes(this.state.currentContent) && sections[index].border < 100) {
                sections[index].border+=5;
            } else if(sections[index].border > 0) {
                sections[index].border-=5;
            }
        });
        this.setState({sections});
        if(this.counter < 100) {
            window.requestAnimationFrame(this.renderBorder);
        } else {
            this.counter = 0;
        }
    }

    goToSession = (index) => {
        this.setState({canScroll: false});
        this.setState({scrolledStatus: ''});
        setTimeout(() => {
            this.setState({scrolledStatus: this.state.currentContent > index ? 'up' : 'down'});
            this.setState({currentContent: index});
            this.renderBorder();
        });
        setTimeout(() => {this.setState({canScroll: true});}, 400);
    }

    render() {

        const renderContent = () => {
            if(this.state.currentContent === 0) {
                return (
                    <div className={'content-item title '+this.state.scrolledStatus}>
                        <h1 className='title'>{this.props.content.me}</h1>
                    </div>
                )
            }
            if(this.state.currentContent === 1) {
                return (
                    <div className={'content-item '+this.state.scrolledStatus}>
                        <p>{this.props.content.me_content}</p>
                    </div>
                )
            }
            if(this.state.currentContent === 2) {
                return (
                    <div className={'content-item title '+this.state.scrolledStatus}>
                        <h1 className='title'>{this.props.content.skills}</h1>
                    </div>
                )
            }
            if(this.state.currentContent === 3) {
                return (
                    <div className={'content-item '+this.state.scrolledStatus}>
                        <h2>{this.props.content.skills_content.user_expirience}</h2>
                        <p className='separator-p'>{this.props.content.skills_content.user_expirience_content}</p>
                        <h2>{this.props.content.skills_content.user_interface}</h2>
                        <p>{this.props.content.skills_content.user_interface_content}</p>
                    </div>
                )
            }
            if(this.state.currentContent === 4) {
                return (
                    <div className={'content-item '+this.state.scrolledStatus}>
                        <h2>{this.props.content.skills_content.criativity_and_co_creation}</h2>
                        <p className='separator-p'>{this.props.content.skills_content.criativity_and_co_creation_content}</p>
                        <h2>{this.props.content.skills_content.hard_skills}</h2>
                        <p>{this.props.content.skills_content.hard_skills_content}</p>
                    </div>
                )
            }
            if(this.state.currentContent === 5) {
                return (
                    <div className={'content-item '+this.state.scrolledStatus}>
                        <h2>{this.props.content.skills_content.technical_knowledge}</h2>
                        <p>{this.props.content.skills_content.technical_knowledge_content}</p>
                    </div>
                )
            }
            if(this.state.currentContent === 6) {
                return (
                    <div className={'content-item title title '+this.state.scrolledStatus}>
                        <h1 className='title'>{this.props.content.work}</h1>
                    </div>
                )
            }
            if(this.state.currentContent === 7) {
                return (
                    <div className={'content-item multi-column '+this.state.scrolledStatus}>
                        {
                            this.props.content.works_content.map(item => {
                                return (
                                    <div className='col-6'>
                                        <h1>{item.title}</h1>
                                        {item.items.map(work => {
                                            return (
                                                <div className='separator-li'>
                                                    <p><b>{work.occupation}</b></p>
                                                    <p>{work.details}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            if(this.state.currentContent === 8) {
                return (
                    <div className={'content-item title '+this.state.scrolledStatus}>
                        <h1 className='title small'>{this.props.content.courses_and_awards}</h1>
                    </div>
                )
            }
            if(this.state.currentContent === 9) {
                return (
                    <div className={'content-item multi-column '+this.state.scrolledStatus}>
                        {
                            this.props.content.courses_and_awards_content.map(item => {
                                return (
                                    <div className='col-6'>
                                        <h1>{item.title}</h1>
                                        {item.items.map(item => {
                                            return (
                                                <div className='separator-li'>
                                                    <p><b>{item.item}</b></p>
                                                    <p>{item.details}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            else {
                return
            }
        }

        const renderText = (index, text) => {
            if(this.state.currentContent === index && window.innerWidth > 700) {
                return (
                    <h3>{text}</h3>
                )
            }
        }

        const renderLine = () => {
            if(window.innerWidth > 700) {
                return (
                    <div className='line'>
                        <div className='line-content'></div>
                    </div>
                )
            }
        }

        const renderSlice = () => {
            if(window.innerWidth > 700) {
                return (
                    <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                    </div>
                )
            }
        }

        return (
            <div 
            className="session about text-selection-diabled" 
            onWheel={this.onScroll}
            onMouseDown={this.onTouchStart}
            onMouseMove={this.onTouchMove}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onMouseEnter={this.onCancel}
            onMouseUp={this.onCancel}>
                <div className='content'>
                    {renderContent()}
                </div>
                {renderLine()}
                <div className='indexes'>
                    {
                        this.state.sections.map((section, index) => {
                            return (
                                <div key={index} className={section.indexes.includes(this.state.currentContent) ? 
                                'index current-index' : 'index not-current-index'} onClick={() => this.goToSession(section.indexes[0])}>
                                    {renderText(section.indexes[0], section.name)}
                                    <span className={'c100 p'+section.border+' index-icon'}>
                                        <div className="circle"></div>
                                        {renderSlice()}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <Link target='_blank' to={process.env.PUBLIC_URL + '/files/curriculo.pdf'}>
                    <div className='download-curriculum'>
                        <h3>{this.props.content.see_curriculum}</h3>
                        <Arrow className='arrow'/>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withRouter(About);
