import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ContactInfo extends Component {
    render() {
        return (
            <div className='contact-info session'>
                <div>
                    <b className='phone'><p>+55 11 98920-9049</p></b>
                    <b className='email'><p>contact@brunocesar.digital</p></b>
                    <Link target='_blank' to={process.env.PUBLIC_URL + '/files/curriculo.pdf'}><p className='curriculum'>{this.props.content.see_curriculum}</p></Link>
                </div>
            </div>
        )
    }
}
